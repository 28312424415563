import { createMuiTheme } from '@material-ui/core/styles';

export const myTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#50b8c1',
      main: '#548891',
      dark: '#355962',
      contrastText: '#fff',
    },
    secondary: {
      light: '#6da2ab',
      main: '#548891',
      dark: '#355962',
      contrastText: '#fff',
    },
    third: {
      light: '#eff5f6',
      main: '#548891', 
      dark: '#355962',
      contrastText: '#fff',
    },
    error:{
      light: '#e57373',
      main: '#f44336',
      dark:'#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#FFAA45',
      dark: '#FFAA45',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#355962',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    menu: {
      main: '#F0F0F0',
      contrastText: '#000',
    }
  },
  typography: {
    fontSize: 12,
  },
} );