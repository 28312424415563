import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import {reducers} from "./reducers/index";
import { history } from "../util/history";
import { routerMiddleware } from 'connected-react-router';

const middleware = [thunk];

/*export const store = createStore(
  reducers(history),
  composeWithDevTools(applyMiddleware(routerMiddleware(history),...middleware))
);*/

export default function configureStore(preloadedState) {
  const store = createStore(
      reducers(history), // root reducer with router state
      preloadedState,
      composeWithDevTools(
          applyMiddleware(
              routerMiddleware(history), // for dispatching history actions
              ...middleware
              // ... other middlewares ...
          ),
      ),
  );

  return store;
}
