import React, {Suspense} from 'react'
import { render } from 'react-dom'
import App from './App'
import "./styles.css";
//import {store} from './redux/store'
import { Provider, ReactReduxContext  } from "react-redux";

import configureStore from './redux/store';
import { history } from "./util/history";

//nyelvesítés
import { IntlProvider} from "react-intl";
import messages_hu from "./translations/hu.json";
import messages_en from "./translations/en.json";

//Toast
import { ToastProvider} from 'react-toast-notifications';
//theme
import {ThemeProvider} from '@material-ui/core/styles';
//import {defaultTheme} from '@material-ui/core/styles/defaultTheme';
import {myTheme} from './components/styles/myTheme';

const messages = {
    'hu': messages_hu,
    'en': messages_en
};

const language = navigator.language.split(/[-_]/)[0];  // language without region code

const store = configureStore(/* provide initial state if any */);


render( <ThemeProvider theme={myTheme}>
<IntlProvider locale={language} messages={messages[language]}>
            <ToastProvider autoDismiss
                autoDismissTimeout={6000}
                placement="bottom-right">
                <Suspense fallback={<div>Loading...</div>}>
                    <Provider store={store} context={ReactReduxContext}>
                        <App history={history} context={ReactReduxContext}/>
                    </Provider>
                </Suspense>
            </ToastProvider>
        </IntlProvider>
        </ThemeProvider>, 
  document.getElementById('root'))
