import React, {lazy} from 'react';
import { useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch} from 'react-router-dom';
//import PrivateRoute from './components/element/PrivateRoute';

const SignIn = lazy(() => import('./components/auth/pages/SignIn'));
const Dashboard = lazy(() => import('./components/Dashboard'));

const App = ({ history, context }) => {
  const { user: user } = useSelector((state) => state.auth);
  return (
    <ConnectedRouter history={history} context={context}>
      <Switch>
            <Route exact path={["/", "/signin"]} component={SignIn} />
            <Route path="/dashboard" component={Dashboard} />
      </Switch>
    </ConnectedRouter>
    
  );
};

export default App;
