import { combineReducers } from "redux";
import {authReducer} from "./auth";
import {messageReducer} from "./message";
import { connectRouter } from 'connected-react-router';

export const reducers =(history) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  message: messageReducer,
});
